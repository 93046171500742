import React, {useState, useEffect} from "react";
import "./OtherPage.scss";
import {wordpressUrl} from "../../utils/variables.js";
import {cleanHtml} from "../../utils/functions";
import {useTranslation} from "react-i18next";
import Helmet from "react-helmet";
import axios from "axios";

const OtherPage = ({title, pageId, isCookiePresent}) => {
    const [data, setData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const {i18n} = useTranslation();
    let helmet = <Helmet><title>{title}</title></Helmet>;

    useEffect(() => {
        const lang = i18n.language.split('-')[0];

        axios.get(`${wordpressUrl}/pages/${pageId}?lang=${lang}`)
            .then(response => response.data)
            .then(result => setData(result?.content?.rendered))
            .catch(error => console.error(error));

    }, [i18n.language, pageId]);

    let content = cleanHtml(data);
    return (
        <section className={`page ${isCookiePresent ? 'page__bigmargin' : ''}`}>
            {helmet}
            <h1 className={'section__title section__title--page'} data-testid={'other-page-title'}>{title}</h1>
            <div className={'section__text'} dangerouslySetInnerHTML={{__html: content}}/>
        </section>
    );
};

export default OtherPage;

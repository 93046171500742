import React from "react";
import {ReactSVG} from "react-svg";
import "./DemoItem.scss";
import Arrow from '../../../images/right-arrow.svg';

const DemoItem = ({title, description, imgPath, hoverClass, url}) => {
    return (
        <a href={url}>
            <div className={'demo-item ' + (hoverClass)}>
                <ReactSVG src={`../../../images/${imgPath}`} className={`div__image div__image--${hoverClass}`} />
                <section className={'section__demo-item'}>
                    <h3 className={'section__title'}>{title}</h3>
                    <p className={'section__subtitle--demo-item'}>{description}</p>
                </section>
                <ReactSVG src={Arrow} className={'arrow arrow--demo-item'} />
            </div>
        </a>
    );
};

export default DemoItem;

import React, {useRef, useEffect, useState} from "react";
import "./DescriptionBlock.scss";
import {wordpressUrl, mainPageInfo} from "../../utils/variables";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {scrollToRef, cleanHtml} from "../../utils/functions";
import axios from "axios";

const DescriptionBlock = () => {
    const [content, setContent] = useState('');
    const {i18n} = useTranslation();
    const infoRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const lang = i18n.language.split("-")[0];
        axios.get(`${wordpressUrl}/pages/${mainPageInfo}?lang=${lang}`)
            .then(response => response.data)
            .then(result => {
                let cleanedContent = cleanHtml(result.content.rendered);
                setContent(cleanedContent);
            })
            .catch(error => console.error(error));
    }, [i18n.language, setContent]);

    useEffect(() => {
        if (location.hash === '#info') {
            scrollToRef(infoRef);
        }
    }, [infoRef, location, content]);

    return <div className={'info'} id={'info'} ref={infoRef}>
        <div dangerouslySetInnerHTML={{__html: content}} />
    </div>
};

export default DescriptionBlock;

import React from "react";
import {useTranslation} from "react-i18next";

function NotFoundPage () {
    const {t} = useTranslation();
    return (
        <div>
            <h2 className={'notfound'}>{t('Nothing found')}</h2>
        </div>
    );
}

export default NotFoundPage;
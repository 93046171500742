import React, {useEffect, useRef, useState} from "react";
import Header from "./header/Header";
import {Trans, useTranslation} from "react-i18next";
import NotFoundPage from "./not-found-page/NotFoundPage";
import OtherPage from "./other-page/OtherPage";
import {api, dataProtectionPolicy} from "../utils/variables";
import Home from "./home/Home";
import {Switch, Route, useLocation} from "react-router-dom";
import CookieConsent, {Cookies} from "react-cookie-consent";
import ReactGA from "react-ga4";
import {googleLocation} from "../utils/functions";
import {Helmet} from "react-helmet";

const Main = ({isCookiePresent}) => {
    const {t,} = useTranslation();

    return (
        <Switch>
            <Route exact path={'/'} component={Home}/>
            <Route exact path={'/api'} render={(props) => <OtherPage {...props} title={t('API')} pageId={api}/>}/>
            <Route exact path={'/data-protection-policy'}
                   render={(props) => <OtherPage {...props} title={t('Data Protection Policy')}
                                                 pageId={dataProtectionPolicy} isCookiePresent={isCookiePresent} />}/>
            <Route exact path={'/file-upload'}
                   render={(props) => <OtherPage {...props} title={'File upload'} pageId={api}/>}/>
            <Route component={NotFoundPage}/>
        </Switch>
    )
};

function App() {
    const {t, i18n} = useTranslation();
    const cookieRef = useRef()
    const [isCookiePresent, setCookiePresent] = useState(false)

    useEffect(() => {
        const cookie = Cookies.get('TartuNLP NER');
        if (cookie) {
            ReactGA.initialize(window.REACT_APP_ANALYTICS_KEY);
            googleLocation();
        }

        if (cookieRef.current && !cookie) {
            setCookiePresent(true)
        }
        else {
            setCookiePresent(false)
        }
    }, []);

    // If URL changes, send data to Google Analytics
    const location = useLocation();
    useEffect(() => {
        const cookie = Cookies.get("TartuNLP NER");
        if (cookie) googleLocation();
    }, [location]);

    return (
        <>
            <Helmet defaultTitle={t('Named Entity Recognition')} titleTemplate={`%s | ${t('Named Entity Recognition')}`}>
                <html lang={i18n.language.split("-")[0]} />
                <title lang={i18n.language.split("-")[0]} />
                <meta name="description" content={t('meta description')} />
                <meta property="og:title" content={t('Named Entity Recognition')} />
                <meta name="twitter:title" content={t('Named Entity Recognition')} />
                <meta property="og:description" content={t('meta description')} />
                <meta name="twitter:description" content={t('meta description')} />
            </Helmet>
            <Header />
            <Main isCookiePresent={isCookiePresent} />
            <CookieConsent
                ref={cookieRef}
                location="bottom"
                cookieName="TartuNLP NER"
                sameSite="strict"
                buttonText={t("cookie button")}
                containerClasses={"cookie-container"}
                contentClasses={"text--cookie"}
                buttonClasses={"button--cookie"}
                disableButtonStyles={true}
                contentStyle={{
                    marginLeft: "0",
                    marginRight: "0",
                    paddingRight: "10px",
                    flexShrink: "0",
                    flexGrow: "1",
                    boxSizing: "border-box"
                }}
                onAccept={() => {
                    ReactGA.initialize(window.REACT_APP_ANALYTICS_KEY);
                    googleLocation();
                    setCookiePresent(false)
                }}
            >
                <Trans i18nKey={'cookie consent'}>
                    This website uses cookies to improve user experience and we store user input and may share
                    it with 3rd party services to provide additional functionality. By continuing to use this
                    site, you agree to our <a href={"/data-protection-policy"} rel="noopener noreferrer" target={"_blank"}>Data Protection Policy</a>.
                </Trans>
            </CookieConsent>
        </>
    );
}

export default App;

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                Features: 'Features',
                Collaboration: 'Collaboration',
                API: 'API',
                Info: 'Information',
                "File upload": "File upload",
                'All demos': 'All demos',
                'All TartuNLP demos': 'All TartuNLP demos',
                'More_info_TartuNLP': 'More information about TartuNLP can be found <1>here</1>',
                Close: 'Close',
                demos: [
                    {
                        title: "Neurokõne", description: "Estonian speech synthesis with a natural sound", hoverClass: "white",
                        imgPath: "icon-neurokone.svg", url: "https://neurokone.ee"
                    },
                    {
                        title: "Neurotõlge", description: "The most skilled translator of local languages", hoverClass: "red",
                        imgPath: "icon-neurotolge.svg", url: "https://translate.ut.ee"
                    },
                    /*{
                        title: "Livesubs", description: "Simultaneous interpretation for conferences", hoverClass: "purple",
                        imgPath: "icon-livesubs.svg", url: "https://livesubs.ee"
                    },*/
                    {
                        title: "Named Entity Recognition", description: "A clever tool for annotating named entities", hoverClass: "yellow",
                        imgPath: "icon-keelekorrektor.svg", url: "https://ner.tartunlp.ai/"
                    }
                ],
                "Nothing found": "Sorry. There is nothing on this page.",
                "cookie consent": "This website uses cookies to improve user experience and we store user input and may " +
                    "share it with 3rd party services to provide additional functionality. By continuing to use this site, " +
                    "you agree to our <1>Data Protection Policy</1>.",
                "cookie button": "I agree",
                "Data Protection Policy": "Data Protection Policy",
                "Analyse": "Analyse",
                "placeholder text": "Enter your text here",
                'What it does': 'What makes it special?',
                'Named Entity Recognition': 'Named Entity Recognition',
                'meta description': 'Named entity recognition (NER) allows us to find words or phrases that contain proper nouns.'
            }
        },
        et: {
            translations: {
                Features: 'PARANDADA!',
                Collaboration: 'Koostöö',
                API: 'API',
                Info: 'Info',
                "File upload": "Faili üleslaadimine",
                'All demos': 'Kõik demod',
                'All TartuNLP demos': 'Kõik TartuNLP demod',
                'More_info_TartuNLP': 'Rohkem infot TartuNLP kohta leiad <1>siit</1>',
                Close: 'Sulge',
                demos: [
                    {
                        title: "Neurokõne", description: "Loomuliku kõlaga eestikeelne kõnesüntesaator", hoverClass: "white",
                        imgPath: "icon-neurokone.svg", url: "https://neurokone.ee"
                    },
                    {
                        title: "Neurotõlge", description: "Kõige vilunum kohalikes keeltes tõlkija", hoverClass: "red",
                        imgPath: "icon-neurotolge.svg", url: "https://translate.ut.ee"
                    },
                    /*{
                        title: "Livesubs", description: "Sünkroontõlge konverentsidel", hoverClass: "purple",
                        imgPath: "icon-livesubs.svg", url: "https://livesubs.ee"
                    },*/
                    {
                        title: "Nimeolemite tuvastaja", description: "Andekas nimeüksuste märgendaja", hoverClass: "yellow",
                        imgPath: "icon-keelekorrektor.svg", url: "https://ner.tartunlp.ai/"
                    }
                ],
                "Nothing found": "Vabandust. Selline leht puudub.",
                "cookie consent": "See veebileht kasutab parema kasutuskogemuse tagamiseks küpsiseid ning kasutajate " +
                    "sisendeid salvestakse ja võidakse edastada kolmandate osapoolte teenustele, kui see on vajalik " +
                    "lehe funktsionaalsuse tagamiseks. Kasutamist jätkates nõustute meie <1>andmekaitsetingimustega</1>.",
                "cookie button": "Nõustun",
                "Data Protection Policy": "Andmekaitsetingimused",
                "Analyse": "Analüüsi",
                "placeholder text": "Sisesta oma tekst siia",
                'What it does': 'Mis teeb selle eriliseks?',
                'Named Entity Recognition': 'Nimeolemite tuvastaja',
                'meta description': 'Nimeolemite tuvastaja võimaldab tekstist leida sõnu või fraase, mis sisaldavad pärisnimesid.'
            }
        }
    },
    fallbackLng: "et",
    debug: false,
    load: 'languageOnly',

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        useSuspense: true
    }
});

export default i18n;

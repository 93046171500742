import React from "react";
import Taggy from "react-taggy";
import {entities} from "../../utils/variables";

const OutputBox = ({text, spans, minHeight}) => {
    return (
        <section className={'output-box'} style={{minHeight: `${minHeight}px`, height: `${minHeight}px`}}>
            <Taggy text={text} spans={spans} ents={entities} />
        </section>
    );
}

export default OutputBox;
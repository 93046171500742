import React from "react";
import { useMediaQuery } from "react-responsive";
import {Trans, useTranslation} from "react-i18next";
import DemoItem from "./DemoItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

import './DemoOverlay.scss';

const DemoOverlay = ({demosSlideInOpen, handleDemosSlideIn, overlayRef}) => {
    const {t, } = useTranslation();

    let demoTitle;
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1024px)'});
    if (isDesktopOrLaptop) {
        demoTitle = <div className={'aside__divider'} id={'close'}><FontAwesomeIcon icon={faTimes} className={'aside__button'}
                                                                                    onClick={handleDemosSlideIn} />
            <h4 className={'aside__title'} onClick={handleDemosSlideIn}>{t('Close')}</h4>
        </div>;
    }
    else {
        demoTitle = <h4 className={'aside__title'}>{t('All TartuNLP demos')}</h4>;
    }

    return (
        <aside className={`demo-overlay ${demosSlideInOpen ? 'aside--active' : ''}`} ref={overlayRef} data-testid={'demo-overlay'}>
            {demoTitle}
            {t('demos', {returnObjects: true}).map((item, index) =>
                <DemoItem key={index} title={item.title} description={item.description} hoverClass={item.hoverClass}
                          imgPath={item.imgPath} url={item.url} />)}
            <p className={'aside__subtitle'}><Trans i18nKey="More_info_TartuNLP">More info about TartuNLP can be found <a href="http://tartunlp.ai">here</a></Trans></p>
        </aside>
    );
}

export default DemoOverlay;

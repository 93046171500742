import React from "react";
import {useTranslation} from "react-i18next";
import {maxChars} from "../../utils/variables";

const InputBox = ({text, handleTextChange, charsUsed, minHeight}) => {
    const {t, } = useTranslation();
    const placeholder = t('placeholder text');

    return (
        <div className={'input-box'} style={{minHeight: `${minHeight}px`}}>
            <textarea className={'div__textarea'} id={'input-textarea'} placeholder={placeholder} onChange={handleTextChange}
                      maxLength={maxChars} value={text} />
            <div className={'box__character-count'}><span>{charsUsed} / {maxChars}</span></div>
        </div>
    );
}

export default InputBox;
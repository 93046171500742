import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import Logo from './../../../images/Tartunlp_logo_black.svg';
import {useTranslation} from "react-i18next";
import "./Navigation.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ReactSVG} from "react-svg";

const Navigation = ({clickDemoButton}) => {
    const {t, i18n} = useTranslation();
    const [navOpen, toggleNav] = useState(false);

    const handleLanguageChange = (e) => {
        e.preventDefault();
        const language = e.target.id;
        i18n.changeLanguage(language)
            .then(() => {
            });
    }

    const toggleNavOpen = () => {
        const header = document.body.querySelector('header');
        header.classList.toggle('header--nav-open');

        // So the background doesn't scroll when scrolling the open hamburger menu
        document.body.classList.toggle('body--fixed');

        const demoOverlay = document.body.querySelector('.demo-overlay');
        demoOverlay.classList.toggle('demo-overlay--active');

        const menuItems = document.body.querySelectorAll('.nav__item');
        if (navOpen) {
            menuItems.forEach((item) => {
                item.classList.remove('nav__item--active');
            });
        } else {
            menuItems.forEach((item) => {
                item.classList.add('nav__item--active');
            });
        }

        toggleNav(!navOpen);
    }

    const closeNav = () => {
        const header = document.body.querySelector('header');
        header.classList.remove('header--nav-open');

        document.body.classList.remove('body--fixed');

        const demoOverlay = document.body.querySelector('.demo-overlay');
        demoOverlay.classList.remove('demo-overlay--active');

        const menuItems = document.body.querySelectorAll('.nav__item');
        menuItems.forEach((item) => item.classList.remove('nav__item--active'));

        toggleNav(false);
    }


    let toggle;
    if (navOpen) {
        toggle = <li className={'nav__item toggle toggle--open'} onClick={() => toggleNavOpen()}><FontAwesomeIcon
            icon={faTimes}/></li>;
    } else {
        toggle =
            <li className={'nav__item toggle'} onClick={() => toggleNavOpen()}><FontAwesomeIcon icon={faBars}/></li>;
    }

    return (
        <nav className='nav'>
            <ul className='nav__list'>
                <li className={'nav__item logo'} onClick={() => closeNav()}>
                    <Link to={'/'} className='nav__link'><ReactSVG src={Logo}/></Link>
                </li>
                <li className='nav__item' onClick={() => closeNav()}>
                    <HashLink to={"/#info"} className='nav__link'
                              scroll={el => el.scrollIntoView({behavior: 'smooth', block: "start"})}
                              data-testid={'nav__item--lang'}>
                        {t('Info')}
                    </HashLink>
                </li>
                {/*<li className='nav__item' onClick={() => closeNav()}>
                    <Link to={'/file-upload'} className='nav__link'>
                        {t('File upload')}
                    </Link>
                </li>*/}
                <li className='nav__item' onClick={() => closeNav()}>
                    <Link to={'/api'} className='nav__link' data-testid={'nav__item'}>
                        {t('API')}
                    </Link>
                </li>
                <li className='nav__item nav__button' onClick={clickDemoButton} data-testid={'button--demo'}>
                    {t('All demos')}
                </li>

                {toggle}

                {i18n.language.split("-")[0] === 'en'
                    ?
                    (
                        <>
                            <li className={`nav__item nav__lang-change nav__lang-change--active ${navOpen ? 'nav__item--active' : ''}`}
                                id={'en'} onClick={e => handleLanguageChange(e)}>EN
                            </li>
                            <li className={`nav__item nav__lang-change ${navOpen ? 'nav__item--active' : ''}`} id={'et'}
                                onClick={e => handleLanguageChange(e)} data-testid={'nav__lang-change'}>ET
                            </li>
                        </>
                    ) : (
                        <>
                            <li className={`nav__item nav__lang-change ${navOpen ? 'nav__item--active' : ''}`} id={'en'}
                                onClick={e => handleLanguageChange(e)}>EN
                            </li>
                            <li className={`nav__item nav__lang-change nav__lang-change--active ${navOpen ? 'nav__item--active' : ''}`}
                                id={'et'} onClick={e => handleLanguageChange(e)}>ET
                            </li>
                        </>
                    )
                }
            </ul>
        </nav>
    );
}

export default Navigation;
export const tartunlpApi = "https://api.tartunlp.ai";
export const wordpressUrl = 'https://tartunlp.ai/wordpress/index.php/wp-json/wp/v2';
export const mainPageInfo = '1409';
export const dataProtectionPolicy  = '1157';
export const api = '1412';

export const entities = [
    {type: 'per', color: {r: 166, g: 226, b: 45}},
    {type: 'loc', color: {r: 67, g: 198, b: 252}},
    {type: 'org', color: {r: 47, g: 187, b: 171}}
];

export const maxChars = 5000;
import React from 'react';
import DescriptionBlock from "../description-block/DescriptionBlock";
import MainBlock from "../main-block/MainBlock";
import {useMediaQuery} from "react-responsive";
import {useWindowSize} from "../../utils/functions";

const Home = () => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const [, height] = useWindowSize();

    const newHeight = isDesktopOrLaptop ? height - 74 - 16 : height - 54 - 16;

    return (
        <>
            <MainBlock minHeight={newHeight} isDesktopOrLaptop={isDesktopOrLaptop} />
            <DescriptionBlock />
        </>
    );
}

export default Home;

import React, {useState} from "react";
import "./MainBlock.scss";
import InputBox from "./InputBox";
import OutputBox from "./OutputBox";
import ScrollDown from "./ScrollDown";
import {useTranslation} from "react-i18next";
import {fetchNER, finalNERs} from "../../utils/functions";

const MainBlock = ({minHeight, isDesktopOrLaptop}) => {
    const [text, setText] = useState('30. juunil 1632. aastal kinnitas Rootsi kuningas Gustav II Adolf oma allkirjaga Nürnbergi all sõjalaagris Academia Dorpatensis\'e asutamisüriku, mis lubab meie suurkooli ajalugu sellest ajast alustada ja auväärseks lugeda.');
    const [charsUsed, setCharsUsed] = useState(text.length);
    const [spans, setSpans] = useState([
        {start: 33, end: 39, type: "LOC"},
        {start: 49, end: 64, type: "PER"},
        {start: 80, end: 89, type: "LOC"},
        {start: 106, end: 128, type: "ORG"}
    ]);
    const [analysedText, setAnalysedText] = useState('30. juunil 1632. aastal kinnitas Rootsi kuningas Gustav II Adolf oma allkirjaga Nürnbergi all sõjalaagris Academia Dorpatensis\'e asutamisüriku, mis lubab meie suurkooli ajalugu sellest ajast alustada ja auväärseks lugeda.');

    const {t,} = useTranslation();

    // On desktop we subtract the height of the button and the scroll down element
    const newMinHeight = isDesktopOrLaptop ? minHeight - 140 : 0;

    const handleTextChange = (e) => {
        e.preventDefault();

        if (isDesktopOrLaptop) {
            const textarea = document.querySelector('.div__textarea');
            textarea.style.fontSize = e.target.value.length >= 1000 ? '16px' : '30px';
            const outputBoxSpans = document.querySelectorAll('.output-box > div > span');
            outputBoxSpans.forEach((span) => span.style.fontSize = e.target.value.length >= 1000 ? '16px' : '30px');
        }

        setText(e.target.value);
        setCharsUsed(e.target.value.length);
    }

    const handleAnalyseClick = () => {
        if (text.length === 0) {
            setSpans([]);
            setAnalysedText('');
        } else {
            fetchNER(text)
                .then(data => {
                    const {text: newAnalysedText, spans: newSpans} = finalNERs(data.result);
                    setAnalysedText(newAnalysedText);
                    setSpans(newSpans);

                    // Change the font size of the output box spans when the text length exceeds the limit
                    if (isDesktopOrLaptop) {
                        const outputBoxSpans = document.querySelectorAll('.output-box > div > span');
                        outputBoxSpans.forEach((span) => span.style.fontSize = text.length >= 1000 ? '16px' : '30px');
                    }
                })
                .catch(err => console.error(err));
        }
    }

    return (
        <div className={'main-block'} style={{minHeight: `${minHeight}px`, height: `${minHeight}px`}} data-testid={'viewport'}>
            <section className={'main-block__section'}>
                <InputBox text={text} handleTextChange={handleTextChange} charsUsed={charsUsed}
                          minHeight={newMinHeight}/>
                <button className={'section__button'} onClick={handleAnalyseClick}>{t('Analyse')}</button>
                <OutputBox text={analysedText} spans={spans} minHeight={newMinHeight}/>
            </section>
            <ScrollDown/>
        </div>
    );
}

export default MainBlock;
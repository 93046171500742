import {useLayoutEffect, useState} from "react";
import ReactGA from 'react-ga4';
import {tartunlpApi} from "./variables";

export const scrollToRef = (ref) => ref.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
});

// Function that takes the original content and strips out all <script> and <style> tags.
export function cleanHtml(content) {
    let div = document.createElement('div');
    div.innerHTML = content;

    let scripts = div.querySelectorAll('style, scripts');
    let i = scripts.length;

    while (i--) {
        scripts[i].parentNode.removeChild(scripts[i]);
    }

    return div.innerHTML;
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export async function fetchNER(text) {
    const settings = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "text": text
        })
    };

    try {
        const response = await fetch(`${tartunlpApi}/v2.0/bert/ner`, settings);
        return response.json()
    } catch (e) {
        return e;
    }
}

export function completeNERs(sentence, previousStart) {
    let start = previousStart;
    let end = start;

    // For NERs that are longer than one word
    let longStart = previousStart;
    let longEnd = previousStart;
    let longEntity = false;

    // Results
    let spans = [];
    let analysedText = '';

    // Look through all the elements in the sentence
    for (let i = 0; i < sentence.length; i++) {
        const elem = sentence[i];
        const word = elem['word'];
        const parts = elem['ner'].split('-');
        const ner = parts[parts.length - 1];

        // If the word is not a punctuation mark
        if (!(',.;:?!'.includes(word))) {
            analysedText = analysedText + word + ' ';

            // Update the end location
            end = previousStart + analysedText.length - 1;

            //console.log(`word: ${word}, ner: ${elem['ner']}, start: ${start}`);
            //console.log(`analysedText: ${analysedText}, length: ${analysedText.length}, end: ${end}`);

            // Check if the elem is part of a longer NER and update necessary variables
            if (elem['ner'].startsWith('B-')) {
                if (longEntity) {
                    let span;
                    if (longEntity !== ner) {
                        span = {start: longStart, end: longEnd, type: longEntity};
                    }
                    else {
                        span = {start: longStart, end: longEnd, type: ner};
                    }
                    spans.push(span);
                    longEntity = false;
                    //console.log(`longEntity span: ${JSON.stringify(span)}`)
                }

                longStart = start;
                longEnd = end;
                longEntity = ner;

                //console.log(`B start --- longEntity: ${longEntity}, start: ${longStart}, end: ${longEnd}`);
                if (i === sentence.length - 1) {
                    //console.log("Olen viimane listis, aga algan B-ga");
                    const span = {start: longStart, end: longEnd, type: ner};
                    spans.push(span);
                    longStart = previousStart;
                    longEnd = previousStart;
                    longEntity = false;
                    analysedText = analysedText + ' \n';
                }
            }
            // Check if the NER continues
            else if (elem['ner'].startsWith('I-')) {
                // TODO: quick fix for when there is a span starting with I- in the middle of the sentence that doesn't belong to the previous span
                //if (spans.length !== 0 && spans[spans.length - 1]['ner'] !== ner) longStart = start;
                longEnd = end;
                longEntity = ner;
                //console.log(`I start --- longEntity: ${longEntity}, start: ${longStart}, end: ${longEnd}`)
            }
            // Check if there is a NER (O is not a NER)
            else if (elem['ner'] !== 'O') {
                //console.log("EI ALGA B, I-GA EGA POLE O!");
                let span;
                // If there is a previous long NER, then add it to the list and update the variables.
                // Otherwise just add it to the list
                if (longEntity) {
                    span = {start: longStart, end: longEnd, type: ner};

                    //console.log(`longEntity span: ${JSON.stringify(span)}`)

                    longEntity = false;
                    longStart = previousStart;
                    longEnd = previousStart;
                }
                else {
                    span = {start: start, end: end, type: ner};
                    //console.log(`normal span: ${JSON.stringify(span)}`)
                }
                spans.push(span);
            }
            // If the elem is not a NER (ner == 'O')
            else {
                // If there is a previous long NER, add it to the list and update the variables.
                // Otherwise do nothing.
                //console.log("Not a NER");
                if (longEntity) {
                    const span = {start: longStart, end: longEnd, type: longEntity};
                    //console.log(`longEntity span: ${JSON.stringify(span)}`);
                    spans.push(span);
                    longEntity = false;
                    longStart = previousStart;
                    longEnd = previousStart;
                }
            }
        }
        // If the elem is a punctuation mark, just add it to the analysed text
        else {
            //console.log("Olen kirjavahemärk");
            // If there is a long NER, add it to the list and update the variables.
            if (longEntity) {
                const span = {start: longStart, end: longEnd, type: longEntity};
                //console.log(`longEntity span: ${JSON.stringify(span)}`);
                longEntity = false;
                longStart = previousStart;
                longEnd = previousStart;
                spans.push(span);
            }

            analysedText = analysedText.trimRight() + word + ' ';
        }

        // Update the start location
        start = previousStart + analysedText.length;
        //console.log(`spans at the end: ${JSON.stringify(spans)}`)
        //console.log(`analysedText at the end: ${analysedText}`);
    }

    return {text: analysedText, spans: spans};
}

export function finalNERs(list) {
    let newCompleteText = '';
    let newCompleteSpans = [];
    let start = 0;
    for (let i = 0; i < list.length; i++) {
        const { text: newAnalysedText, spans: newSpans } = completeNERs(list[i], start);
        start = start + newAnalysedText.length;
        newCompleteText = newCompleteText + newAnalysedText;
        newCompleteSpans.push(...newSpans);
    }

    return {text: newCompleteText, spans: newCompleteSpans};
}

export function googleLocation() {
    ReactGA.set({page: window.location.pathname});
    ReactGA.send("pageview");
}